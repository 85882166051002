<template>
  <el-main>
    <el-form class="el-form-search" label-width="130px">
      <el-form-item label="区域管理员姓名：">
        <el-input size="small" v-model="searchItem.name" placeholder="请输入区域管理员姓名："></el-input>
      </el-form-item>
      <el-form-item label="区域管理员昵称：">
        <el-input size="small" v-model="searchItem.nickname" placeholder="请输入区域管理员昵称："></el-input>
      </el-form-item>
      <el-form-item label="区域管理员电话：">
        <el-input size="small" v-model="searchItem.tel" placeholder="请输入区域管理员电话："></el-input>
      </el-form-item>
      <el-form-item label="所属地区：">
        <el-cascader size="small" v-model="searchItem.area_id" :props="{ value: 'id', label: 'name', children: '_child' }" placeholder="请选择或搜索" filterable :options="areaList"></el-cascader>
      </el-form-item>
      <el-form-item label="注册时间：">
        <el-date-picker
          v-model="searchItem.create_time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
          @change="timeChange"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="30px">
        <el-button type="primary" size="small" @click="getShopList('search')">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="contacts_name" label="区域管理员姓名" align="center"></el-table-column>
      <el-table-column label="区域管理员信息" align="center">
        <template v-slot="{ row }"><UserInfo :name="row.nickname" :avatar="row.avatar"></UserInfo></template>
      </el-table-column>
      <el-table-column prop="contacts_phone" label="管理员电话" align="center"></el-table-column>
      <el-table-column prop="contacts_name" label="管理区域" align="center">
        <template v-slot="{ row }">
          {{ row.province_name + row.city_name + row.area_name }}
        </template>
      </el-table-column>
      <el-table-column label="区域用户" align="center">
        <template v-slot="{ row }">
          <div class="cupo" @click="$router.push('/user/O2OuserList?cityId=' + JSON.stringify([row.province_id, row.city_id, row.area_id]))">{{ row.area_user_count }}</div>
        </template>
      </el-table-column>
      <el-table-column label="区域推广员" align="center">
        <template v-slot="{ row }">
          <div class="cupo" @click="$router.push('/extension/promoterList?cityId=' + JSON.stringify([row.province_id, row.city_id, row.area_id]))">{{ row.area_distribution_count }}</div>
        </template>
      </el-table-column>
      <el-table-column label="区域回收员" align="center">
        <template v-slot="{ row }">
          <div class="cupo" @click="$router.push('/user/recyclerList?cityId=' + JSON.stringify([row.province_id, row.city_id, row.area_id]))">{{ row.area_recyler_count }}</div>
        </template>
      </el-table-column>
      <el-table-column label="累计奖励" align="center">
        <template v-slot="{ row }">￥{{ Number(row.total_reward).toFixed(2) }}</template>
      </el-table-column>
      <el-table-column label="注册时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="editRegional(row)">编辑</el-button>
          <el-button type="text" @click="deleteRegional(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import UserInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate.js';
export default {
  components: {
    Paging,
    UserInfo,
  },
  data() {
    return {
      getDateformat,
      searchItem: {
        name: '',
        nickname: '',
        contacts_phone: '',
        area_id: [],
        create_time: [],
      },
      areaList: [],
      dataList: [],
      page: 1,
      rows: 10,
      total: 0,
    };
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getShopList();
    },
    cancelSearch() {
      this.searchItem = {
        name: '',
        nickname: '',
        tel: '',
        area_id: [],
        create_time: [],
      };
      this.getShopList(1);
    },
    // 获取页面列表信息
    getShopList(style) {
      if (style) {
        this.page = 1;
      }
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      let time = this.searchItem.create_time;
      if (time.length) {
        // 如果需要按时间查询
        obj.create_time = [Math.ceil(time[0].getTime() / 1000), Math.ceil(time[1].getTime() / 1000)];
        if (obj.create_time[0] == obj.create_time[1]) {
          obj.create_time[1] += 60 * 60 * 24 - 1;
        }
      }
      if (this.searchItem.name) {
        obj.name = this.searchItem.name;
      }
      if (this.searchItem.nickname) {
        obj.nickname = this.searchItem.nickname;
      }
      if (this.searchItem.tel) {
        obj.nickname = this.searchItem.tel;
      }
      if (this.searchItem.area_id.length) {
        obj.area_id = this.searchItem.area_id[2];
      }
      this.$axios.post(this.$api.O2O.regionalAdministrator.shareholderList, obj).then(res => {
        if (res.code === 0) {
          this.dataList = res.result.list;
          this.total = res.result.total_number;
        }
      });
    },
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          this.areaList = res.result.list;
        }
      });
    },
    // 去编辑区域管理员信息
    editRegional(data) {
      this.$router.push({
        path: '/region/editAdministrators',
        query: { id: data.id },
      });
    },
    // 删除区域管理员信息
    deleteRegional(data) {
      this.$confirm('此操作将永久删除该管理员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.O2O.regionalAdministrator.delRegional, { id: data.id });
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('删除成功');
            if (this.dataList.length === 1 && this.page != 1) {
              this.page--;
            }
            this.getShopList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
  },
  created() {
    this.getArea();
    this.getShopList();
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  & > .el-button {
    margin-bottom: 10px;
  }
  .cupo {
    cursor: pointer;
  }
}
</style>
